import React from 'react';
import { MDBAccordion, MDBAccordionItem, MDBContainer } from 'mdb-react-ui-kit';
import pic from '../../assets/two/app.png';

const Reviews = () => {
  return (
    <section
      id='faq'
      style={{
        borderRadius: 24,
        boxShadow: '0 0 10px rgba(0,0,0,.15)',
      }}
    >
      <div className='container'>
        <div className='s-80'></div>
        <h3 style={{ color: "white" }} className='title one-title ml-10'>FAQ</h3>
        {/*<div className='s-10'></div>*/}
        <div className='row py-4'>
          <div className='col-lg-6'>
            <MDBContainer className='mt-5'>
              <MDBAccordion alwaysOpen initialActive={1}>
                <MDBAccordionItem
                  collapseId={1}
                  style={{ color: "white" }}
                  headerTitle={<span style={{ color: "white" }}>What age group is KidsBank suitable for?</span>}
                >
                  KidsBank is designed for children aged 5-12, providing a safe
                  and supervised platform for trading toys.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={2}
                  style={{ color: "white" }}
                  headerTitle={<span style={{ color: "white" }}>How do parents monitor transactions?</span>}
                >
                  Parents can easily oversee all transactions through the app’s
                  parental control features, ensuring every trade and purchase
                  is secure.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={3}
                  style={{ color: "white" }}
                  headerTitle={<span style={{ color: "white" }}>Is the app free to use? </span>}
                >
                  Yes, KidsBank is free to download and use, with no hidden
                  fees. Optional premium features are available for enhanced
                  functionality.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={4}
                  style={{ color: "white" }}
                  headerTitle={<span style={{ color: "white" }}>What types of toys can be traded?</span>}
                >
                  Kids can trade a wide range of toys, including action figures,
                  dolls, educational games, and more, all listed by other kids
                  in the community.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={5}
                  style={{ color: "white" }}
                  headerTitle={<span style={{ color: "white" }}>How does KidsBank ensure secure transactions? </span>}
                >
                  All transactions are monitored and protected with advanced
                  security measures, including encryption and parental
                  approvals, to ensure a safe trading environment.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={6}
                  style={{ color: "white" }}
                  headerTitle={<span style={{ color: "white" }}>Can kids use KidsBank without parental supervision?</span>}
                >
                  No, KidsBank requires parental supervision for all activities,
                  ensuring that every trade and purchase is safe and
                  appropriate.
                </MDBAccordionItem>
              </MDBAccordion>
            </MDBContainer>
          </div>
          <div className='col-lg-6'>
            <div className='m-20'></div>
            <img
              src={pic}
              alt='Features'
              width={600}
              height={456}
              className='w-100'
            />
          </div>
        </div>
        <div className='s-60'></div>
      </div>
    </section>
  );
};

export { Reviews };

/*
old variant of this component

import React from 'react';
import i1 from '../../assets/one/u1.jpg';
import i2 from '../../assets/one/u2.jpg';
import i3 from '../../assets/one/u3.jpg';

const Reviews = () => {
  return (
    <section id='reviews' className='w-100 py-5 land-one'>
      <div className='s-40'></div>
      <div className='container tc'>
        <h3 className='title one-title c-white'>Happy Users</h3>
        <div className='s-40 m-40'></div>
        <div className='row'>
          <div className='col-lg-4'>
            <div
              className='r-24 px-5 py-3 cr-blue'
              style={{ backgroundColor: '#6E6DA4' }}
            >
              <div className='s-30'></div>
              <img
                src={i1}
                alt='Jack Spark'
                width={120}
                height={120}
                className='r-48'
              />
              <div className='s-20'></div>
              <p className='title-mini c-white one-font'>Jack Spark, Parent</p>
              <p className='title-part c-white one-font'>
                “My kids love trading their toys safely!”
              </p>
            </div>
          </div>
          <div className='col-lg-4'>
            <div
              className='r-24 px-5 py-3 cr-blue'
              style={{ backgroundColor: '#6E6DA4' }}
            >
              <div className='s-30'></div>
              <img
                src={i2}
                alt='Emily Robbins'
                width={120}
                height={120}
                className='r-48'
              />
              <div className='s-20'></div>
              <p className='title-mini c-white one-font'>
                Emily Robbins, Parent
              </p>
              <p className='title-part c-white one-font'>
                “Safe, fun, and educational for my kids!”
              </p>
            </div>
          </div>
          <div className='col-lg-4'>
            <div
              className='r-24 px-5 py-3 cr-blue'
              style={{ backgroundColor: '#6E6DA4' }}
            >
              <div className='s-30'></div>
              <img
                src={i3}
                alt='Ken Miley'
                width={120}
                height={120}
                className='r-48'
              />
              <div className='s-20'></div>
              <p className='title-mini c-white one-font'>Ken Miley, Parent</p>
              <p className='title-part c-white one-font'>
                “A fantastic way to teach financial skills.”
              </p>
            </div>
          </div>
        </div>
        <div className='s-40'></div>
      </div>
    </section>
  );
};

export { Reviews };

*/